@import '../theme-bootstrap';

.sbHolder {
  background-color: #f1f4f5;
  border: solid 1px #dddcdc;
  font-family: 'HelveticaNeueLTStd45Light', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
  font-size: 14px;
  font-weight: normal;
  height: 46px;
  position: relative;
  width: 100%;
}

.sbHolder:focus .sbSelector {
}

.sbSelector {
  display: block;
  height: 44px;
  line-height: 46px;
  outline: none;
  overflow: hidden;
  text-indent: 10px;
  margin: 0 30px 0 44px;
  background-color: #f1f4f5;
}

.skin_types .sbSelector {
  margin: 0 30px 0 0;
}

.sbSelector:link,
.sbSelector:visited,
.sbSelector:hover {
  color: #000;
  outline: none;
  text-decoration: none;
}

.sbToggle {
  background: #f1f4f5 url('/media/export/images/mobile_sku_arrow_dn.png') 17px 17px no-repeat;
  display: block;
  height: 44px;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
}
/* .sbToggle:hover{
	background: url('/media/export/images/mobile_sku_arrow_up.png') 10px 10px no-repeat;
} */
.sbToggleOpen {
  background: #f1f4f5 url('/media/export/images/mobile_sku_arrow_up.png') 17px 17px no-repeat;
}
/* .sbToggleOpen:hover{
	background: url('/media/export/images/mobile_sku_arrow_up.png') 10px 10px no-repeat;
} */
.sbHolderDisabled {
  background-color: #3c3c3c;
  border: solid 1px #515151;
}

.sbHolderDisabled .sbHolder {
}

.sbHolderDisabled .sbToggle {
}

.sbOptions {
  background-color: #f1f4f5;
  border: solid 1px #dddcdc;
  list-style: none;
  left: -1px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 44px;
  width: 100%;
  z-index: 1;
  overflow-y: auto;
}

.sbOptions li {
  padding: 0 0 0 44px;
  border: solid 1px #dddcdc;
  border-width: 1px 0 0;
}

.skin_types .sbOptions li {
  padding: 0;
}

.sbOptions a {
  /* border-bottom: dotted 1px #515151; */
  display: block;
  outline: none;
  padding: 15px 0 15px 12px;
}

.sbOptions a:link,
.sbOptions a:visited {
  color: #808080;
  text-decoration: none;
  display: block;
  background-color: #f1f4f5;
}

.sbOptions a:hover,
.sbOptions a:focus,
.sbOptions a.sbFocus {
  color: #000;
  background-color: #dddcdc;
}

.sbOptions li.last a {
  border-bottom: none;
}

.sbOptions .sbDisabled {
  border-bottom: dotted 1px #515151;
  color: #999;
  display: block;
  padding: 7px 0 7px 3px;
}

.sbOptions .sbGroup {
  border-bottom: dotted 1px #515151;
  color: #ebb52d;
  display: block;
  font-weight: bold;
  padding: 7px 0 7px 3px;
}

.sbOptions .sbSub {
  padding-left: 17px;
}
